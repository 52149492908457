import { computed, ComputedRef, ref } from '@vue/composition-api';
import { DisplayDate, getTargetPlanNames, getTargetPlanName, getTargetProductName } from '@/admin/util';

import {
  User,
  UserInfos,
  Count,
  UserCounts,
  SalesInfos,
  SubscriptionCounts,
  getStatSalesInfos,
  getStatSubscriptionCounts,
  getStatUserCounts,
  getStatUserInfos,
} from '@/admin/stat';
import { getSubscriptionPlans, SubscriptionPlan } from '@/admin/payment';
import { getProducts, getDigitalContentProducts, ProductResponse } from '@/admin/product';

interface UserItem extends User {
  age: string;
  ageGroup: string;
  csv: {
    isDeleted: string;
    isFailedLatestSubscriptionPayment: string;
    isUncompletedSignup: string;
    subscriptionPlanNames: string[];
  };
}

interface SalesInfoItem {
  id: string;
  salesTime: string;
  salesTimeRange: string;
  paymentType: 'credit' | 'convenienceStore' | 'docomo' | 'au' | 'softbank' | 'paypay';
  price: number;
  totalPrice: number;
  count: number;
  type: string;
  csv: {
    date: string;
    salesTime: string;
    name: string;
    paymentType: string;
  };
}

interface DataItem {
  date: number; // データフィルター用の日付。時刻については現状対応していないため全て00:00。
  displayDate: string;
}
export interface UserCountItem extends DataItem {
  counts: Count;
}
export interface InfoItem extends DataItem {
  totalPrice: number;
  infos: SalesInfoItem[];
}

export interface SubscriptionCountItem extends DataItem {
  plans: {
    subscriptionPlanId: string;
    subscriptionPlanName: string;
    price: string;
    count: number;
    isArchive: boolean;
    csv: {
      date: string;
      time: string;
    };
  }[];
}

class Report {
  private _isLoading = ref(true);
  private _userInfosResponse = ref<UserInfos | null>(null);
  private _userCountsResponse = ref<UserCounts | null>(null);
  private _salesInfosResponse = ref<SalesInfos | null>(null);
  private _subscriptionCountsResponse = ref<SubscriptionCounts | null>(null);
  private _subscriptionPlans = ref<SubscriptionPlan[]>([]);
  private _products = ref<ProductResponse[]>([]); // 注意：digitalContentProductsの型をproductsと揃えているが、正確には持っているプロパティに違いがあるので、この変数を使用することがある場合は注意する

  private _date = computed<number>(() => new Date(this._userInfosResponse.value?.date || '').getTime());
  private _dateString = computed<string>(() => this._userInfosResponse.value?.date || '');
  private _date29daysAgo = computed<number>(() =>
    new Date(this._date.value).setDate(new Date(this._date.value).getDate() - 29)
  );
  private _time = computed<string>(() => this._userInfosResponse.value?.time || '');
  private _users: ComputedRef<UserItem[]> = computed(() => {
    const data = this._userInfosResponse.value;
    if (!data) return [];

    const today = new Date(this._date.value);
    const users = data.users.map((user) => {
      const birthdate = user.birthdate === '-' ? user.birthdate : new Date(user.birthdate);
      const age =
        birthdate === '-'
          ? '-'
          : today.getFullYear() -
            birthdate.getFullYear() +
            (today.getTime() < new Date(today.getFullYear(), birthdate.getMonth(), birthdate.getDate()).getTime()
              ? -1
              : 0);
      const ageTensplace = age !== '-' ? Math.floor(age / 10) : null;
      const ageGroup =
        ageTensplace && 0 < ageTensplace && ageTensplace <= 6
          ? `${ageTensplace}0代`
          : ageTensplace && 7 <= ageTensplace
          ? '70代以上'
          : 'その他';

      const subscriptionPlanNames = getTargetPlanNames(user.subscriptionPlanIds, this._subscriptionPlans.value);

      return {
        ...user,
        age: age.toString(),
        ageGroup: ageGroup,
        csv: {
          isDeleted: user.isDeleted ? '○' : '×',
          isFailedLatestSubscriptionPayment: user.isFailedLatestSubscriptionPayment ? '○' : '×',
          isUncompletedSignup: user.isUncompletedSignup ? '○' : '×',
          subscriptionPlanNames: subscriptionPlanNames.length ? subscriptionPlanNames : ['-'],
        },
      };
    });
    return users;
  });
  private _userCounts: ComputedRef<UserCountItem[]> = computed(() => {
    const data = this._userCountsResponse.value;
    if (!data) return [];

    const dates = Object.keys(data).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

    return dates.map((item) => {
      return {
        date: new Date(item).getTime(),
        displayDate: item,
        counts: data[item].counts,
      };
    });
  });
  private _salesInfos: ComputedRef<{ products: InfoItem[]; plans: InfoItem[] }> = computed(() => {
    const data = this._salesInfosResponse.value;
    if (!data)
      return {
        products: [],
        plans: [],
      };

    const productInfos: InfoItem[] = [];
    const planInfos: InfoItem[] = [];

    const dates = Object.keys(data).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

    dates.forEach((date) => {
      const dayData = data[date];
      const sales = dayData.sales;
      const products = sales.reduce((acc: SalesInfoItem[], current) => {
        if (current.type === 'product')
          acc.push({
            ...current,
            csv: {
              date: date,
              salesTime: current.salesTime,
              name: getTargetProductName(current.id, this._products.value),
              paymentType: current.paymentType,
            },
          });
        return acc;
      }, []);
      const plans = sales.reduce((acc: SalesInfoItem[], current) => {
        if (current.type === 'subscriptionPlan')
          acc.push({
            ...current,
            csv: {
              date: date,
              salesTime: current.salesTime,
              name: getTargetPlanName(current.id, this._subscriptionPlans.value),
              paymentType: current.paymentType,
            },
          });
        return acc;
      }, []);

      productInfos.push({
        displayDate: date,
        date: new Date(date).getTime(),
        totalPrice: products.reduce((acc, current) => acc + current.totalPrice, 0),
        infos: products,
      });
      planInfos.push({
        displayDate: date,
        date: new Date(date).getTime(),
        totalPrice: plans.reduce((acc, current) => acc + current.totalPrice, 0),
        infos: plans,
      });
    });

    return {
      products: productInfos,
      plans: planInfos,
    };
  });
  private _subscriptionCounts: ComputedRef<SubscriptionCountItem[]> = computed(() => {
    const data = this._subscriptionCountsResponse.value;
    if (!data) return [];

    const dates = Object.keys(data).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

    return dates.map((date) => {
      const plans = data[date].plans
        .map((item) => {
          const thisPlan = this._subscriptionPlans.value.find(
            (plan) => plan.subscriptionPlanId === item.subscriptionPlanId
          );
          if (!thisPlan)
            return {
              subscriptionPlanId: item.subscriptionPlanId,
              subscriptionPlanName: '',
              price: '',
              count: 0,
              isArchive: true,
              csv: {
                date,
                time: data[date].time,
              },
            };
          else
            return {
              subscriptionPlanId: item.subscriptionPlanId,
              subscriptionPlanName: thisPlan?.subscriptionPlanName || '',
              price:
                `${
                  thisPlan.interval === 'year'
                    ? '年額'
                    : thisPlan.interval === 'month'
                    ? thisPlan.intervalCount === 1
                      ? '月額'
                      : `${thisPlan.intervalCount}ヶ月`
                    : thisPlan.interval === 'day' && thisPlan.intervalCount === 1
                    ? '日額'
                    : `${thisPlan.intervalCount}日`
                }${thisPlan.price.toLocaleString()}円` || '',
              count: item.totalCount,
              isArchive: thisPlan ? thisPlan.isArchive : true,
              csv: {
                date,
                time: data[date].time,
              },
            };
        })
        .filter((item) => !item.isArchive);
      return {
        date: new Date(date).getTime(),
        displayDate: date,
        plans: plans,
      };
    });
  });

  get isLoading() {
    return this._isLoading.value;
  }
  get date() {
    return this._date.value;
  }
  get dateString() {
    return this._dateString.value;
  }
  get time() {
    return this._time.value;
  }
  get date29daysAgo() {
    return this._date29daysAgo.value;
  }
  get displayDate() {
    return `${this._dateString.value} ${this._time.value} 現在`;
  }
  get users() {
    return this._users.value;
  }
  get activeUsers() {
    return this._users.value.filter((item) => !item.isDeleted);
  }
  get paidMembershipUsers() {
    return this.activeUsers.filter((item) => !!item.subscriptionPlanIds.length);
  }
  get hasPaidMembershipUsers() {
    return !!this.paidMembershipUsers.length;
  }
  get userCounts() {
    return this._userCounts.value;
  }
  get salesInfos() {
    return this._salesInfos.value;
  }
  get subscriptionCounts() {
    return this._subscriptionCounts.value;
  }
  get activeSubscriptionPlans() {
    return this._subscriptionPlans.value.filter((item) => !item.isArchive);
  }
  get hasSubscriptionPlans() {
    return !!this._subscriptionPlans.value.length;
  }
  get hasActiveSubscriptionPlans() {
    return !!this._subscriptionPlans.value.filter((item) => !item.isArchive).length;
  }
  get hasProducts() {
    return !!this._products.value.length;
  }
  get productOptions() {
    const data = this._products.value.map((item) => ({
      value: item.productId,
      text: `${item.isArchive ? '【削除済】' : ''} ${item.productName} （価格：${item.price.toLocaleString()}円）`,
    }));
    return [{ value: '', text: '総売上' }, ...data];
  }

  getReportData = () => {
    const now = DisplayDate.now();
    const date = now.format('YYYY/MM/DD');
    const time = now.format('hh:00');
    if (this._dateString.value === date && this._time.value === time) return;

    this._isLoading.value = true;

    Promise.all([
      getStatUserInfos(),
      getStatUserCounts(),
      getStatSalesInfos(),
      getStatSubscriptionCounts(),
      getSubscriptionPlans(),
      getProducts(),
      getDigitalContentProducts(),
    ])
      .then(
        ([
          userInfos,
          userCounts,
          salesInfos,
          subscriptionCounts,
          subscriptionPlans,
          products,
          digitalContentProducts,
        ]) => {
          this._userInfosResponse.value = userInfos;
          this._userCountsResponse.value = userCounts;
          this._salesInfosResponse.value = salesInfos;
          this._subscriptionCountsResponse.value = subscriptionCounts;
          this._subscriptionPlans.value = subscriptionPlans;
          // productsとdigitalContentProductsは正確には持っているプロパティに違いがあるが、該当のプロパティを使用していないので一旦型を揃えておいてV2移行で実装し直す
          this._products.value = products.concat(digitalContentProducts);
        }
      )
      .finally(() => (this._isLoading.value = false));
  };

  getFilteredData = <T extends DataItem>(data: T[], dates: (number | null)[]): { dates: string; items: T[] } | null => {
    if (!data.length) return null;

    // 初期表示：過去30日間のデータ
    if (!dates[0] && !dates[1]) {
      dates = [this._date29daysAgo.value, this._date.value];
    }

    // 指定された期間
    const filterStart = dates[0];
    const filterEnd = dates[1];

    // データが存在する期間
    const dataStart = data[0].date;
    const dataEnd = data.slice(-1)[0].date;

    // フィルター
    const start = filterStart && dataStart <= filterStart && filterStart <= dataEnd ? filterStart : dataStart;
    const end = filterEnd && dataStart <= filterEnd && filterEnd <= dataEnd ? filterEnd : dataEnd;
    const filteredData = data.filter((item) => item.date && start <= item.date && item.date <= end);

    const startDate = new DisplayDate(start).format('YYYY/MM/DD');
    const endDate = new DisplayDate(end).format('YYYY/MM/DD');
    const displayDates = startDate === endDate ? startDate : `${startDate} 〜 ${endDate}`;

    return {
      dates: displayDates,
      items: filteredData,
    };
  };
}

const report = new Report();
export default report;
