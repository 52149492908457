



















































































































































import { defineComponent, computed, ref, watch } from '@vue/composition-api';
import Chart from 'chart.js/auto';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';
import ReportItem from '@/components/ReportItem.vue';
import FcDate from '@/components/FcDate.vue';
import FcDateFilter from '@/components/FcDateFilter.vue';
import report from '@/composition/report';
import { pointColors, basicColors } from '@/util/chartColor';

export default defineComponent({
  name: 'ReportMembers',
  components: {
    FcRoleLoading,
    FcRoleDeny,
    ReportItem,
    FcDate,
    FcDateFilter,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('report'));

    // データ取得
    report.getReportData();

    // 総売上推移
    const salesCountsChangeFilterDates = ref<(number | null)[]>([null, null]);
    const salesCountsChange = computed(() => {
      // プラン・個別課金ともに登録がない
      if (!report.hasSubscriptionPlans && !report.hasProducts) return null;

      const salesPlanInfos = report.salesInfos.plans;
      const salesProductInfos = report.salesInfos.products;
      if (!salesPlanInfos.length && !salesProductInfos.length) return null;

      const filteredPlanInfos = report.getFilteredData(salesPlanInfos, salesCountsChangeFilterDates.value);
      const filteredProductInfos = report.getFilteredData(salesProductInfos, salesCountsChangeFilterDates.value);
      if (
        !filteredPlanInfos ||
        !filteredProductInfos ||
        filteredPlanInfos.items.length !== filteredProductInfos.items.length
      )
        return null;

      const filteredDataDates = filteredPlanInfos.dates;
      const filteredPlanInfosItem = filteredPlanInfos.items;
      const filteredProductInfosItem = filteredProductInfos.items;

      const planTotalSales = filteredPlanInfosItem.reduce((acc, current) => acc + current.totalPrice, 0);
      const productTotalSales = filteredProductInfosItem.reduce((acc, current) => acc + current.totalPrice, 0);

      return {
        displayDate: filteredDataDates,
        labels: filteredPlanInfosItem.map((item) => item.displayDate),
        total: filteredPlanInfosItem.map((item, i) => item.totalPrice + filteredProductInfosItem[i].totalPrice),
        plans: filteredPlanInfosItem.map((item) => item.totalPrice),
        products: filteredProductInfosItem.map((item) => item.totalPrice),
        totalSales: (planTotalSales + productTotalSales).toLocaleString(),
        planTotalSales: planTotalSales.toLocaleString(),
        productTotalSales: productTotalSales.toLocaleString(),
      };
    });

    const salesCountsChangeChartCanvas = ref<HTMLCanvasElement | null>(null);
    const salesCountsChangeChartHiddenIndexes = ref<number[]>([]); // 再レンダリング時に非表示にしておくデータのindexを保管
    let salesCountsChangeChart: any = null;
    const createSalesChangeCountsChart = () => {
      if (!salesCountsChangeChartCanvas.value || report.isLoading || !salesCountsChange.value) return;

      const labels = salesCountsChange.value.labels;
      const datasets = [];
      if (report.hasActiveSubscriptionPlans && report.hasProducts)
        datasets.push({
          type: 'line',
          label: '累計売上',
          data: salesCountsChange.value.total,
          borderColor: pointColors.orange,
          backgroundColor: pointColors.orange,
        });
      if (report.hasActiveSubscriptionPlans)
        datasets.push({
          type: 'line',
          label: 'プラン売上',
          data: salesCountsChange.value.plans,
          borderColor: pointColors.blue,
          backgroundColor: pointColors.blue,
        });
      if (report.hasProducts)
        datasets.push({
          type: 'line',
          label: '個別課金売上',
          data: salesCountsChange.value.products,
          borderColor: pointColors.red,
          backgroundColor: pointColors.red,
        });

      if (salesCountsChangeChart) {
        salesCountsChangeChart.data.labels = labels;
        salesCountsChangeChart.data.datasets = datasets;
        salesCountsChangeChartHiddenIndexes.value.forEach((item) =>
          salesCountsChangeChart.setDatasetVisibility(item, false)
        );
        salesCountsChangeChart.update();
      } else {
        salesCountsChangeChart = new Chart(salesCountsChangeChartCanvas.value, {
          data: {
            labels: labels,
            datasets: datasets,
          },
          options: {
            plugins: {
              legend: {
                labels: { usePointStyle: true, pointStyle: 'rect' },
                onClick: function(event: any, legendItem: any, legend: any) {
                  // https://www.chartjs.org/docs/3.9.1/configuration/legend.html#configuration-options
                  const index = legendItem.datasetIndex;
                  const ci = legend.chart;
                  if (ci.isDatasetVisible(index)) {
                    ci.hide(index);
                    legendItem.hidden = true;
                    salesCountsChangeChartHiddenIndexes.value.push(index);
                  } else {
                    ci.show(index);
                    legendItem.hidden = false;
                    salesCountsChangeChartHiddenIndexes.value = salesCountsChangeChartHiddenIndexes.value.filter(
                      (item) => item !== index
                    );
                  }
                },
              },
            },
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                  callback: function(value: number) {
                    if (Math.floor(value) === value) return value;
                  },
                },
              },
            },
          },
        });
      }
    };

    // 有料プラン売上推移
    const subscriptionSalesChangeFilterDates = ref<(number | null)[]>([null, null]);
    const subscriptionSalesChange = computed(() => {
      const sales = report.salesInfos.plans;
      const activeSubscriptionPlans = report.activeSubscriptionPlans;
      if (!sales.length || !activeSubscriptionPlans) return null;

      const filteredData = report.getFilteredData(sales, subscriptionSalesChangeFilterDates.value);
      if (!filteredData) return null;

      const filteredDataDates = filteredData.dates;
      const filteredDataItems = filteredData.items;
      const labels = filteredDataItems.map((item) => item.displayDate);
      const data = activeSubscriptionPlans.reduce(
        (
          acc: {
            subscriptionPlanId: string;
            subscriptionPlanName: string;
            sales: number[];
            price: string;
            count: string;
            totalSales: string;
          }[],
          current
        ) => {
          const filteredSales = filteredDataItems.reduce((_acc: number[], _current) => {
            _acc.push(
              _current.infos
                .filter((item) => current.subscriptionPlanId === item.id)
                .reduce((__acc, __current) => __acc + __current.totalPrice, 0)
            );
            return _acc;
          }, []);
          const salesCount = filteredDataItems.reduce(
            (_acc: number, _current) =>
              _acc +
              _current.infos
                .filter((item) => current.subscriptionPlanId === item.id)
                .reduce((accCount: number, currentCount) => accCount + currentCount.count, 0),
            0
          );
          acc.push({
            subscriptionPlanId: current.subscriptionPlanId,
            subscriptionPlanName: current.subscriptionPlanName || '',
            count: salesCount.toLocaleString(),
            sales: filteredSales,
            totalSales: `${filteredSales.reduce((_acc, _current) => _acc + _current, 0).toLocaleString()}円`,
            price:
              `${
                current.interval === 'year'
                  ? '年額'
                  : current.interval === 'month'
                  ? current.intervalCount === 1
                    ? '月額'
                    : `${current.intervalCount}ヶ月`
                  : current.interval === 'day' && current.intervalCount === 1
                  ? '日額'
                  : `${current.intervalCount}日`
              }${current.price.toLocaleString()}円` || '',
          });
          return acc;
        },
        []
      );
      return {
        displayDate: filteredDataDates,
        labels,
        data,
      };
    });

    const subscriptionSalesChangeChartCanvas = ref<HTMLCanvasElement | null>(null);
    const subscriptionSalesChangeChartHiddenIndexes = ref<number[]>([]); // 再レンダリング時に非表示にしておくデータのindexを保管
    let subscriptionSalesChangeChart: any = null;
    const createSubscriptionSalesChangeChart = () => {
      if (!subscriptionSalesChangeChartCanvas.value || report.isLoading || !subscriptionSalesChange.value) return;

      const labels = subscriptionSalesChange.value.labels;
      const datasets = subscriptionSalesChange.value.data.map((item, index) => {
        const colorIndex = index < basicColors.length ? index : index % basicColors.length;
        return {
          borderColor: basicColors[colorIndex],
          backgroundColor: basicColors[colorIndex],
          label: item.subscriptionPlanName,
          data: item.sales,
        };
      });

      if (subscriptionSalesChangeChart) {
        subscriptionSalesChangeChart.data.labels = labels;
        subscriptionSalesChangeChart.data.datasets = datasets;
        subscriptionSalesChangeChartHiddenIndexes.value.forEach((item) =>
          subscriptionSalesChangeChart.setDatasetVisibility(item, false)
        );
        subscriptionSalesChangeChart.update();
      } else {
        subscriptionSalesChangeChart = new Chart(subscriptionSalesChangeChartCanvas.value, {
          type: 'line',
          data: {
            labels: labels,
            datasets: datasets,
          },
          options: {
            plugins: {
              legend: {
                labels: { usePointStyle: true, pointStyle: 'rect' },
                onClick: function(event: any, legendItem: any, legend: any) {
                  // https://www.chartjs.org/docs/3.9.1/configuration/legend.html#configuration-options
                  const index = legendItem.datasetIndex;
                  const ci = legend.chart;
                  if (ci.isDatasetVisible(index)) {
                    ci.hide(index);
                    legendItem.hidden = true;
                    subscriptionSalesChangeChartHiddenIndexes.value.push(index);
                  } else {
                    ci.show(index);
                    legendItem.hidden = false;
                    subscriptionSalesChangeChartHiddenIndexes.value = subscriptionSalesChangeChartHiddenIndexes.value.filter(
                      (item) => item !== index
                    );
                  }
                },
              },
            },
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                  callback: function(value: number) {
                    if (Math.floor(value) === value) return value;
                  },
                },
              },
            },
          },
        });
      }
    };

    // 個別課金内訳
    const productSalesFilterDates = ref<(number | null)[]>([null, null]);
    const selectedProduct = ref('');
    const productSales = computed(() => {
      const sales = report.salesInfos.products;
      if (!sales.length) return null;

      const filteredData = report.getFilteredData(sales, productSalesFilterDates.value);
      if (!filteredData) return null;

      const filteredDataDates = filteredData.dates;
      const filteredItems = filteredData.items;

      const selectedProductData = filteredItems.reduce(
        (acc: { displayDate: string; totalPrice: number; count: number }[], current) => {
          const filteredInfos = selectedProduct.value
            ? current.infos.filter((item) => selectedProduct.value === item.id)
            : current.infos;
          const item = filteredInfos.length
            ? {
                displayDate: current.displayDate,
                totalPrice: filteredInfos.reduce((_acc, _current) => _acc + _current.totalPrice, 0),
                count: filteredInfos.reduce((_acc, _current) => _acc + _current.count, 0),
              }
            : {
                displayDate: current.displayDate,
                totalPrice: 0,
                count: 0,
              };
          acc.push(item);
          return acc;
        },
        []
      );

      return {
        displayDate: filteredDataDates,
        totalPrice: selectedProductData.reduce((acc: number, current) => acc + current.totalPrice, 0).toLocaleString(),
        count: selectedProductData.reduce((acc: number, current) => acc + current.count, 0).toLocaleString(),
        labels: selectedProductData.map((item) => item.displayDate),
        data: selectedProductData.map((item) => item.totalPrice),
      };
    });

    const productSalesChangeChartCanvas = ref<HTMLCanvasElement | null>(null);
    const productSalesChangeChartHiddenIndexes = ref<number[]>([]); // 再レンダリング時に非表示にしておくデータのindexを保管
    let productSalesChangeChart: any = null;
    const createProductSalesChangeChart = () => {
      if (!productSalesChangeChartCanvas.value || report.isLoading || !productSales.value) return;

      const labels = productSales.value.labels;
      const datasets = [
        {
          borderColor: pointColors.green,
          backgroundColor: pointColors.green,
          data: productSales.value.data,
        },
      ];

      if (productSalesChangeChart) {
        productSalesChangeChart.data.labels = labels;
        productSalesChangeChart.data.datasets = datasets;
        productSalesChangeChartHiddenIndexes.value.forEach((item) =>
          productSalesChangeChart.setDatasetVisibility(item, false)
        );
        productSalesChangeChart.update();
      } else {
        productSalesChangeChart = new Chart(productSalesChangeChartCanvas.value, {
          type: 'line',
          data: {
            labels: labels,
            datasets: datasets,
          },
          options: {
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                  callback: function(value: number) {
                    if (Math.floor(value) === value) return value;
                  },
                },
              },
            },
          },
        });
      }
    };

    // グラフの描画
    watch(
      () => [report.isLoading, salesCountsChange.value, salesCountsChangeChartCanvas.value],
      () => createSalesChangeCountsChart()
    );
    watch(
      () => [report.isLoading, subscriptionSalesChange.value, subscriptionSalesChangeChartCanvas.value],
      () => createSubscriptionSalesChangeChart()
    );
    watch(
      () => [report.isLoading, productSales.value, productSalesChangeChartCanvas.value],
      () => createProductSalesChangeChart()
    );

    return {
      pageTitle: '売上詳細',
      myRoleSettings,
      isPlanService: process.env.VUE_APP_GROUP_TYPE === 'plan',
      report,
      salesCountsChangeFilterDates,
      salesCountsChangeChartCanvas,
      salesCountsChange,
      subscriptionSalesChangeFilterDates,
      subscriptionSalesChangeChartCanvas,
      subscriptionSalesChange,
      productSalesFilterDates,
      selectedProduct,
      productSales,
      productSalesChangeChartCanvas,
      basicColors,
    };
  },
});
